html,
body,
#root {
  height: 100%;
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.container {
  max-width: 500px;
}

.min-100 {
  min-height: 100%;
}

fieldset {
  width: 100%;
}

.fade-enter,
.fade-appear {
  opacity: 0.01;
}

.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

:root {
  --PhoneInputCountryFlag-borderColor: transparent;
}
